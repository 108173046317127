import React from "react";
import { Navigate } from "react-router-dom";
import MainContainer from "../mainContainer/index";
import Utils from "../../Utils/pathname";
interface Props {
  component: JSX.Element;
}

const PrivateRoute: React.FC<Props> = ({ component }: Props) => {
  
  return (
    <React.Fragment>
      {localStorage.getItem("access_token") !== null ||
      sessionStorage.getItem("access_token") !== null ? (
        <MainContainer children={component} />
      ) : (
        <Navigate to={Utils.Login} />
      )}
    </React.Fragment>
  );
};
export default PrivateRoute;
