import Utils from "../../Utils"
import { PaymentHistoryModal } from "../../model"
const { PAYMENT_HISTORY } = Utils.ActionName
export const paymentHistoryDataReducer = (
    state: PaymentHistoryModal = new PaymentHistoryModal(),
    action: any
) => {
    switch (action.type) {
        case PAYMENT_HISTORY:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};