import Utils from "../../Utils"
import {LinkedAccountModel } from "../../model"
const {LINKED_ACCOUNT } = Utils.ActionName

export const linkedAccountReducer = (
    state: LinkedAccountModel = new LinkedAccountModel(),
    action: any
) => {
    switch (action.type) {
        case LINKED_ACCOUNT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};