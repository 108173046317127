import Utils from "../../Utils";
import { FaqModal , SupportModel} from "../../model";

const { FAQ_DATA, SUPPORT } = Utils.ActionName;

export const faqReducer = (state: FaqModal = new FaqModal(), action: any) => {
  switch (action.type) {
    case FAQ_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const supportReducer = (state: SupportModel = new SupportModel(), action: any) => {
  switch (action.type) {
    case SUPPORT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
