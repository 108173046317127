import { EditableComponentModal, InputMatrixModal, videoUrlModal } from '../../model'
import Utils from '../../Utils'
const { EDITABLE_COMPONENT_DATA, UPDATE_CUSTOMIZABLE_COMPONENT, INPUT_MATRIX, VIDEO_URL } = Utils.ActionName
export const editableComponentReducer = (
    state: EditableComponentModal = new EditableComponentModal(),
    action: any
) => {
    switch (action.type) {
        case EDITABLE_COMPONENT_DATA:
            return { ...state, ...action.payload };

        case UPDATE_CUSTOMIZABLE_COMPONENT:
            return {...state, ...{...state, ...action.payload.data}}
        default:
            return state;
    }
};

export const inputMatrixReducer = (
    state: InputMatrixModal = new InputMatrixModal(),
    action: any
) => {
    switch (action.type) {
        case INPUT_MATRIX:
            return {...state, ...action.payload};
        default:
        return state;
    }
}

export const videoUrlReducer = (
    state: videoUrlModal = new videoUrlModal(),
    action: any
) => {
    switch (action.name) {
        case VIDEO_URL:
            return {...state, ...action.payload};
        default: 
        return state
    }
}