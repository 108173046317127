import Utils from "../../Utils"
import { FavListModal, FavTemplateListModal, ToggleModal } from "../../model"
const { FAV_LIST,FAV_TEMPLATE_LIST, TOGGLE_MODAL } = Utils.ActionName

export const favListReducer = (
    state: FavListModal = new FavListModal(),
    action: any
) => {
    switch (action.type) {
        case FAV_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export const favTemplateListReducer = (
    state: FavTemplateListModal = new FavTemplateListModal(),
    action: any
) => {
    switch (action.type) {
        case FAV_TEMPLATE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export const toggleModalReducer = (
    state: ToggleModal = new ToggleModal(),
    action: any
) => {
    switch (action.type) {
        case TOGGLE_MODAL:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};