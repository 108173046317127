import Utils from "../../Utils";

export const generateUrl = (queryParameters: any, baseURL: any) => {
//  console.warn(queryParameters, queryParameters)
  const { pageNo, limit } = queryParameters;
  if (
    queryParameters.type &&
    queryParameters.searchKey &&
    queryParameters.categoryFilter
  ) {
    return `${baseURL}?pageNo=${pageNo}&limit=${limit}&type=${queryParameters.type}&categoryFilter=${queryParameters.categoryFilter}&searchKey=${queryParameters.searchKey}`;
  } else if (queryParameters.type && queryParameters.searchKey) {
    return `${baseURL}?pageNo=${pageNo}&limit=${limit}&type=${queryParameters.type}&searchKey=${queryParameters.searchKey}`;
  } else if (queryParameters.listType && queryParameters.searchKey) {
    return `${baseURL}?pageNo=${pageNo}&limit=${limit}&listType=${queryParameters.listType}&searchKey=${queryParameters.searchKey}`;
  } else if (queryParameters.searchKey) {
    return `${baseURL}?pageNo=${pageNo}&limit=${limit}&searchKey=${queryParameters.searchKey}`;
  } else if (queryParameters.listType) {
    return `${baseURL}?pageNo=${pageNo}&limit=${limit}&listType=${queryParameters.listType}&type=${queryParameters.type}`;
  } else if (queryParameters.type && queryParameters.categoryId) {
    return `${baseURL}?pageNo=${pageNo}&limit=${limit}&type=${queryParameters.type}&categoryId=${queryParameters.categoryId}`;
  } else if (queryParameters.type) {
    return `${baseURL}?pageNo=${pageNo}&limit=${limit}&type=${queryParameters.type}`;
  }
  return `${baseURL}?pageNo=${pageNo}&limit=${limit}`;
};
export const logOut = () => {
  localStorage.clear();
  Utils.constants.setAuthorizationToken();
  window.location.reload();
};
export const logoutApp = (navigate: any, setShowDropdown: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(2, "Please check your internet connection!");
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    Utils.api.putApiCall(
      Utils.endPoints.logout,
      "",
      (respData: any) => {
        const { data } = respData;
        if (data.code === 200) {
          logOut();
          navigate(Utils.Pathname.Login);
        } else {
          Utils.showAlert(2, data.message);
        }
      },
      (error: any) => {
        const { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};
const templateList = (queryParameters: any, type: boolean = true) => {
  const value = generateUrl(queryParameters, Utils.endPoints.templateList);
  return (dispatch: any) => {
    Utils.api.getApiCall(
      generateUrl(queryParameters, Utils.endPoints.templateList),
      "",
      (responseData: any) => {
        const { data } = responseData;
        if (data.code === 200) {
          dispatch({
            payload: {
              templateData: data.data,
              templateDataQueryParameters: {
                pageNo: data.data.pageNo,
                limit: data.data.limit,
                totalPages: data.data.totalPage,
              },
            },
            type: Utils.ActionName.TEMPLATE_LIST_DATA,
          });
          if (window.location.pathname.split("/")[1] === "dashboard") {
            if (!queryParameters.categoryFilter && data.data.category) {
              sessionStorage.setItem(
                "category",
                JSON.stringify(
                  data.data.category.map((value: any) => {
                    return { ...value, isSelected: false };
                  })
                )
              );
            }
            const categoryData: any = sessionStorage.getItem("category");
            const formattedData = JSON.parse(categoryData);

            dispatch({
              payload: {
                searchResult: type ? data.data : {},
                recentSearchResult: [],
                category: formattedData,
              },
              type: Utils.ActionName.SEARCH_RESULT,
            });
          }
        } else {
          Utils.showAlert(2, data.message);
        }
      },
      (error: any) => {
        const { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};
export const getRecentSearchResults = () => {
  return (dispatch: any) => {
    Utils.api.getApiCall(
      Utils.endPoints.recentSearch,
      "",
      (responseData: any) => {
        const { data } = responseData;
        if (data.code === 200) {
          dispatch({
            payload: {
              recentSearchResult: data.data,
            },
            type: Utils.ActionName.SEARCH_RESULT,
          });
        } else {
          Utils.showAlert(2, data.message);
        }
      },
      (error: any) => {
        const { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};
export const checkSubscription = () => {
  return (dispatch: any) => {
    Utils.api.getApiCall(
      Utils.endPoints.checkSubscription,
      "",
      (responseData: any) => {
        const { data } = responseData;
        if (data.code === 200) {
          sessionStorage.setItem("isSubscribed", data.data[0].isSubscribed);
          dispatch({
            payload: {
              subscriptionValidData: data.data[0],
            },
            type: Utils.ActionName.SUBSCRIPTION_VALID,
          });
        } else {
          Utils.showAlert(2, data.message);
        }
      },
      (error: any) => {
        const { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};
export default templateList;
