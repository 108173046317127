import {
  FaceBookPagesModel,
  LinkedinLoginModel,
  ScheduleFacebookPostModel,
  ScheduleLinkedinPostModel,
  SaveDataModel,
  LinkedinProfileModel,
  InstaAccessModel,
  FacebookLoginModel,
} from "../../model";
import Utils from "../../Utils";
const {
  FB_LOGIN,
  FB_PAGES,
  SCHEDULE_FACEBOOK,
  LINKEDIN_LOGIN,
  SAVE_DATA,
  SCHEDULE_LINKEDIN,
  LINKEDIN_PROFILE,
  INSTA_ACCESS,
} = Utils.ActionName;

export const facebookPagesReducer = (
  state: FaceBookPagesModel = new FaceBookPagesModel(),
  action: any
) => {
  switch (action.type) {
    case FB_PAGES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const scheduleFacebookPostReducer = (
  state: ScheduleFacebookPostModel = new ScheduleFacebookPostModel(),
  action: any
) => {
  switch (action.type) {
    case SCHEDULE_FACEBOOK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const linkedInReducer = (
  state: LinkedinLoginModel = new LinkedinLoginModel(),
  action: any
) => {
  switch (action.type) {
    case LINKEDIN_LOGIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const linkedinProfileReducer = (
  state: LinkedinProfileModel = new LinkedinProfileModel(),
  action: any
) => {
  switch (action.type) {
    case LINKEDIN_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const saveDataReducer = (
  state: SaveDataModel = new SaveDataModel(),
  action: any
) => {
  switch (action.type) {
    case SAVE_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const ScheduleLinkedinPostReducer = (
  state: ScheduleLinkedinPostModel = new ScheduleLinkedinPostModel(),
  action: any
) => {
  switch (action.type) {
    case SCHEDULE_LINKEDIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const instaAccessReducer = (
  state: InstaAccessModel = new InstaAccessModel(),
  action: any
) => {
  switch (action.type) {
    case INSTA_ACCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const facebookLoginReducer = (
  state: FacebookLoginModel = new FacebookLoginModel(),
  action: any
) => {
  switch (action.type) {
    case FB_LOGIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
