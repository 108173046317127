import {
  DashboardDataModal,
  CategoryListDataModal,
  TemplateDetailsModal,
} from "../../model";
import Utils from "../../Utils";

const { DASHBOARD_DATA, CATEGORY_LIST, TEMPLATE_DETAILS } = Utils.ActionName;

export const dashboardDataReducer = (
  state: DashboardDataModal = new DashboardDataModal(),
  action: any
) => {
  switch (action.type) {
    case DASHBOARD_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const categoryListDataReducer = (
  state: CategoryListDataModal = new CategoryListDataModal(),
  action: any
) => {
  switch (action.type) {
    case CATEGORY_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const templateDetailsReducer = (
  state: TemplateDetailsModal = new TemplateDetailsModal(),
  action: any
) => {
  switch (action.type) {
    case TEMPLATE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
