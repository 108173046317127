import Utils from "../../Utils";
import { EditProfileModel, ProfileModel } from "../../model";

const { EDIT_PROFILE , PROFILE } = Utils.ActionName;

export const editprofileReducer = (
  state: EditProfileModel = new EditProfileModel(),
  action: any
) => {
  switch (action.type) {
    case EDIT_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const profileReducer = (
  state: ProfileModel = new ProfileModel(),
  action: any
) => {
  switch (action.type) {
    case PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
