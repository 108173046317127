import { CircularProgress, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

function PageLoader(){
    const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderImg: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        // backgroundColor: '#0000004d',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1215,
        "& p": {
          color: '#ffffff',
          margin: '20px 0'
        }
      }
  })
);
const classes = useStyles();
    return(
        <div className={classes.loaderImg}>
            
                <CircularProgress sx={{
                    color: '#FF3053',
                }} />
                <p>Uploading Image ...</p>
           
        </div>
    )
}

export default PageLoader;