import * as React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownMenuContainer: {
      backgroundColor: "#fff",
      padding: "20px 20px 10px 20px",
      borderRadius: "10px",
      position: "absolute",
      // right: "0",
      // top: "50px",
      zIndex: "9999",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
  })
);
interface Props {
  children: any;
  showDropdown: boolean;
  right: string;
  top: string;
}
export default function Dropdown({
  children,
  showDropdown,
  right,
  top,
}: Props) {
  const classes = useStyles();

  return (
    <div
      className={classes.dropdownMenuContainer}
      style={{
        display: showDropdown ? "block" : "none",
        right: right,
        top: top,
      }}
    >
      {children}
    </div>
  );
}
