const ActionName = {
  LOADING: "loading",
  USER_DATA: "userData",
  SIGN_UP: "signUp",
  TOGGLE_SIDENAV: "toggleSidenav",
  DASHBOARD_DATA: "dashboardData",
  TEMPLATE_LIST_DATA: "templateListData",
  TEAM_MEMBER_DATA: "teamMemberData",
  COMPANY_DATA: "companyData",
  SOCIAL_SIGNIN: "social_signin",
  CATEGORY_LIST: "categoryList",
  TEMPLATE_DETAILS: "templateDetails",
  FAV_LIST: "favoritesList",
  FAV_TEMPLATE_LIST: "favTempList",
  EDITABLE_COMPONENT_DATA: "editableComponentData",
  UPDATE_COMPONENT_DATA: "updateComponentData",
  UPDATE_CUSTOMIZABLE_COMPONENT: "updatecustomizablecomponent",
  INPUT_MATRIX: "inputMatrix",
  VIDEO_URL: "videoUrl",
  TOGGLE_MODAL: "toggle_modal",
  SEARCH_RESULT: "searchResult",
  DROPDOWN: "dropdown",
  FAQ_DATA: "faqData",
  SUPPORT: "support",
  EDIT_PROFILE: "editProfile",
  PROFILE: "profile",
  MY_TEMPLATES: "myTemplates",
  SUBSCRIPTION_PLAN: "subscription-plan",
  FB_LOGIN: "fb-login",
  FB_PAGES: "fb-pages",
  SCHEDULE_FACEBOOK: "schedule-facebook",
  LINKEDIN_LOGIN: "linkedin-signin",
  LINKEDIN_PROFILE: "linkedin-profile",
  SAVE_DATA: "save-data",
  SCHEDULE_LINKEDIN: "schedule-linkedin",
  INSTA_ACCESS: "insta-access",
  PAYMENT_HISTORY: "payment-history",
  SUBSCRIPTION_VALID: "subscriptionValid",
  DELETE_SCHEDULE_POST: "delete-schedule-post",
  EDIT_SCHEDULED_POST: "edit-scheduled-post",
  INSTAGRAM_PAGES: "instagram-pages",
  SCHEDULE_INSTAGRAM: "schedule-instagram",
  DOWNLOADABLE_URL: "downloadable-url",
  LINKED_ACCOUNT: "linked-account",
  EDIT_IMAGE_DATA: "edit_image_data",
  UPDATE_IMAGE_DATA: "update_image_data",
  IMAGE_INDEX: 'image_index',
  SHARESOCIALMEDIA: 'share_social_media',
  CURRENTSELECTEDPOST: 'curren_selected_post_model',
  ISTHUMBNAILSHOW: 'thumbnail_show'
};

export default ActionName;
