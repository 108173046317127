import { SocialSignInModel } from "../../model";
import Utils from "../../Utils";
const { SOCIAL_SIGNIN } = Utils.ActionName;
export const socialSignInReducer = (
  state: SocialSignInModel = new SocialSignInModel(),
  action: any   
) => {
  switch (action.type) {
    case SOCIAL_SIGNIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
