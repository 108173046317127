import Utils from "../../Utils";
import {
  MyTemplateModel,
  EditScheduledPostModel,
  GetDownladableUrl,
  ShareSocialMediaModel,
  CurrentSelectedPostModel
} from "../../model";
const { MY_TEMPLATES, EDIT_SCHEDULED_POST, DOWNLOADABLE_URL, SHARESOCIALMEDIA, CURRENTSELECTEDPOST } =
  Utils.ActionName;

export const myTemplateReducer = (
  state: MyTemplateModel = new MyTemplateModel(),
  action: any
) => {
  switch (action.type) {
    case MY_TEMPLATES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const editScheduledPostReducer = (
  state: EditScheduledPostModel = new EditScheduledPostModel(),
  action: any
) => {
  switch (action.type) {
    case EDIT_SCHEDULED_POST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const getDownladableUrlReducer = (
  state: GetDownladableUrl = new GetDownladableUrl(),
  action: any
) => {
  switch (action.type) {
    case DOWNLOADABLE_URL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ShareSocialMediaReducer = (
  state: ShareSocialMediaModel = new ShareSocialMediaModel(),
  action: any
) => {
  switch (action.type) {
    case SHARESOCIALMEDIA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const CurrentSelectedPostReducer = (
  state: CurrentSelectedPostModel = new CurrentSelectedPostModel(),
  action: any
) => {
  switch (action.type) {
    case CURRENTSELECTEDPOST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
