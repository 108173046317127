import Utils from "../../Utils";
import { SubscriptionPlanModel } from "../../model"
const { SUBSCRIPTION_PLAN } = Utils.ActionName;

export const subscriptionPlanReducer = (state: SubscriptionPlanModel = new SubscriptionPlanModel(), action: any) => {
    switch (action.type) {
        case SUBSCRIPTION_PLAN:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};