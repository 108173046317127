import { EditImageModal, videoUrlModal, IsThumbnailShowModel } from '../../model'
import Utils from '../../Utils'
const { EDIT_IMAGE_DATA, UPDATE_IMAGE_DATA, VIDEO_URL, IMAGE_INDEX, ISTHUMBNAILSHOW } = Utils.ActionName
export const EditImageReducer = (
    state: EditImageModal = new EditImageModal(),
    action: any
) => {
    switch (action.type) {
        case EDIT_IMAGE_DATA:
            return { ...state, ...action.payload };

        case UPDATE_IMAGE_DATA:
            // debugger
            return {...state, ...{...state, ...action.payload}};
        case IMAGE_INDEX:
            // debugger
            return {...state, ...{...state, ...action.payload}}
        default:
            return state;
    }
};



export const videoUrlReducer = (
    state: videoUrlModal = new videoUrlModal(),
    action: any
) => {
    switch (action.name) {
        case VIDEO_URL:
            return {...state, ...action.payload};
        default: 
        return state
    }
}

export const IsThumbnailShowReducer = (
    state: IsThumbnailShowModel = new IsThumbnailShowModel(),
    action: any
) => {
    switch (action.name) {
        case ISTHUMBNAILSHOW:
            return {...state, ...action.payload};
        default: 
        return state
    }
}