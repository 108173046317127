import { UserDataModel } from '../../model'
import Utils from '../../Utils'
const { USER_DATA } = Utils.ActionName
export const userDataReducer = (
    state: UserDataModel = new UserDataModel(),
    action: any
) => {
    switch (action.type) {
        case USER_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};