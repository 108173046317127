import React, { Suspense } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme, Theme } from "@mui/material";
import LocalImages from "../../Utils/images";
import Loading from "../../components/loading";
interface Props {
  children?: JSX.Element;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperContainer: {
      width: "100%",
      display: "flex",
      height: "100vh",
      maxHeight: "100vh",
      overflow: "hidden !important",
    },
    bannerImageContainer: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      width: "50%",
    },
    childrenContainer: {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "10px",
        borderRadius: "30px",
        cursor: "grab",
      },
      "&::-webkit-scrollbar-track": {
        background: " #efefef !important",
        borderRadius: "0px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        width: "10px",
        background: `${theme.palette.primary.light} !important`,
        border: "1.7px solid #e9f5fb",
        borderRadius: "10px !important",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      width: "50%",
    },
  })
);
const LoginContainer: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.wrapperContainer}>
      <div className={classes.bannerImageContainer}>
        <figure style={{ height: "100%", width: "100%", margin: "0px" }}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={LocalImages.LeftDivImg}
            alt="banner"
          />
        </figure>
      </div>
      <Suspense fallback={<Loading />}>
        <div className={classes.childrenContainer}>{children}</div>
      </Suspense>
    </div>
  );
};

export default LoginContainer;
