import {
  TemplateListDataModal,
  SearchResultModal,
  DropdownModal,
  SubscriptionValidDataModal,
} from "../../model";
import Utils from "../../Utils";
const { TEMPLATE_LIST_DATA, SEARCH_RESULT, DROPDOWN, SUBSCRIPTION_VALID } =
  Utils.ActionName;
export const templateListDataReducer = (
  state: TemplateListDataModal = new TemplateListDataModal(),
  action: any
) => {
  switch (action.type) {
    case TEMPLATE_LIST_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const searchResultReducer = (
  state: SearchResultModal = new SearchResultModal(),
  action: any
) => {
  switch (action.type) {
    case SEARCH_RESULT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const subscriptionValidDataReducer = (
  state: SubscriptionValidDataModal = new SubscriptionValidDataModal(),
  action: any
) => {
  switch (action.type) {
    case SUBSCRIPTION_VALID:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const dropDownReducer = (
  state: DropdownModal = new DropdownModal(),
  action: any
) => {
  switch (action.type) {
    case DROPDOWN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
