import { SignUpDataModel } from '../../model'
import Utils from '../../Utils'
const { SIGN_UP } = Utils.ActionName
export const signUpDataReducer = (
    state: SignUpDataModel = new SignUpDataModel(),
    action: any
) => {
    switch (action.type) {
        case SIGN_UP:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};               