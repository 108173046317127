import React from "react";
import { css } from "@emotion/react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      justifyContent: "center",
      width: "50%",
      alignItems: "center",
    },
  })
);
import FadeLoader from "react-spinners/FadeLoader";
function Loading() {
  const color = "#FF3053";
  const override = css`
    display: flex;
    flex:1,
    justify-content:center,
    align-items:center,
    width:100%,
    margin: 0 auto;
    border-color: #ff3053;
  `;
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <FadeLoader
        color={color}
        loading={true}
        css={override}
        height={15}
        width={5}
        radius={10}
        margin={2}
      />
    </div>
  );
}

export default Loading;
