import { ToggleSidenavModel } from '../../model'
import Utils from '../../Utils'
const { TOGGLE_SIDENAV } = Utils.ActionName
export const toggleSideNavReducer = (
    state: ToggleSidenavModel = new ToggleSidenavModel(),
    action: any
) => {
    switch (action.type) {
        case TOGGLE_SIDENAV:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};