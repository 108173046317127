import { bgcolor } from "@mui/system";
import React from "react";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { ReducersModel } from "../../model";
import Utils from "../../Utils/pathname";
import LoginContainer from "../loginContainer/index";

interface Props {
  component?: JSX.Element;
  routeType?: number;
}

const PublicRoute: React.FC<Props> = ({ component, routeType = 1 }) => {
  const { autoGeneratedPassword } = useSelector(
    (state: ReducersModel) => state.signUpDataReducer
  );

  if (autoGeneratedPassword) {
    return <Suspense fallback={""}>{component}</Suspense>;
  }
  const isInstaLogin = JSON.parse(localStorage.getItem("instaLogin") as any);
  const lastPath = localStorage.getItem("lastPath");

  // const templateId = localStorage.getItem("templateId");
  // const colorParam = localStorage.getItem("colorParam");
  const getNav = () => {
    if (isInstaLogin) {
      localStorage.setItem("instaToken", window.location.search.split("=")[1]);

      return <Navigate to={lastPath as string} />;
    }
    return <Navigate to={Utils.Dashboard} />;
  };
  return (
    <React.Fragment>
      {localStorage.getItem("access_token") === null &&
      sessionStorage.getItem("access_token") === null ? (
        routeType === 2 ? (
          <Suspense fallback={""}>{component}</Suspense>
        ) : (
          <LoginContainer>{component}</LoginContainer>
        )
      ) : (
        getNav()
      )}
    </React.Fragment>
  );
};
export default PublicRoute;
