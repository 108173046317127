import LocalImages from "./images"
interface SideNavigation {
  name: string;
  path: string;
  activeIcon: any;
  inactiveIcon: any;
}
export const sideNavigation: SideNavigation[] = [
  {
    name: "Dashboard",
    path: "dashboard",
    activeIcon: LocalImages.DASHBOARD_ACTIVE,
    inactiveIcon: LocalImages.DASHBOARD_INACTIVE
  },
  {
    name: "Teams",
    path: "teams",
    activeIcon: LocalImages.TEAMS_LOGO_ACTIVE,
    inactiveIcon: LocalImages.TEAMS_LOGO_INACTIVE
  },
  {
    name: "My Templates",
    path: "mytemplates",
    activeIcon: LocalImages.TEMPLATES_LOGO_ACTIVE,
    inactiveIcon: LocalImages.TEMPLATES_LOGO_INACTIVE
  },
  {
    name: "My Favorite",
    path: "myfavorites",
    activeIcon: LocalImages.FAVORITE_LOGO_ACTIVE,
    inactiveIcon: LocalImages.FAVORITE_LOGO_INACTIVE
  },
  // {
  //   name: "Settings",
  //   path: "settings",
  //   activeIcon: LocalImages.SETTINGS_LOGO_ACTIVE,
  //   inactiveIcon: LocalImages.SETTINGS_LOGO_INACTIVE
  // },
  // {
  //   name: "VideoEditor",
  //   path: "video-editor",
  //   activeIcon: LocalImages.SETTINGS_LOGO_ACTIVE,
  //   inactiveIcon: LocalImages.SETTINGS_LOGO_INACTIVE
  // }
];
export const cardBgColor = ["A7D9E6", "FFCD5C", "FF3F3F", "007ACC", "36AEF5", "8D9BFF"]
export const subscriptionPlan = [
  {
    planeName: "Golden Plan",
    planDescription: "Lorem dsf dsfksdf sdf sdfsdf sdfsdfsdfsdfsd sdf sdfsdfsdf",
    planCost: "12.90",
    planDuration: "month",
    planServices: [
      {
        service: "Service 1",
        isProvided: true
      },
      {
        service: "Service 2",
        isProvided: true
      },
      {
        service: "Service 3",
        isProvided: true
      },
      {
        service: "Service 4",
        isProvided: true
      },
      {
        service: "Service 5",
        isProvided: true
      },
    ]
  },
  {
    planeName: "Platinum Plan",
    planDescription: "Lorem dsf dsfksdf sdf sdfsdf sdfsdfsdfsdfsd sdf sdfsdfsdf",
    planCost: "50.90",
    planDuration: "year",
    planServices: [
      {
        service: "Service 1",
        isProvided: true
      },
      {
        service: "Service 2",
        isProvided: true
      },
      {
        service: "Service 3",
        isProvided: true
      },
      {
        service: "Service 4",
        isProvided: true
      },
      {
        service: "Service 5",
        isProvided: true
      },
    ]
  },
]

