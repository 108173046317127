import { TeamMembersDataModal, CompanyDataModal } from '../../model'
import Utils from '../../Utils'
const { TEAM_MEMBER_DATA, COMPANY_DATA } = Utils.ActionName
export const teamMembersDataReducer = (
    state: TeamMembersDataModal = new TeamMembersDataModal(),
    action: any
) => {
    switch (action.type) {
        case TEAM_MEMBER_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export const companyDataReducer = (
    state: CompanyDataModal = new CompanyDataModal(),
    action: any
) => {
    switch (action.type) {
        case COMPANY_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};