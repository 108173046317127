import React from "react";
import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import LocalImages from "../../Utils/images";
import { sideNavigation } from "../../Utils/constData";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReducersModel } from "../../model";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideNavigationContainer: {
      position: "fixed",
      top: "0px",
      left: "0px",
      width: "249px",
      [theme.breakpoints.down(600)]:{
        width: "100% !important",
        height: "60px",
        top:"auto",
        bottom:"0",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: 9,
        boxShadow: '0 -2px 10px #00000052'
      },
      height: "100vh",
      backgroundColor: "#F8F9FE",
      padding: "20px 20px 0 20px",
      overflow: "hidden",
      transition: "all 0.5s ease-in-out",
      whiteSpace: "nowrap",
      display: "flex",
      flexDirection: "column",
      // [theme.breakpoints.between(0, 600)]: {
      //   display: "none",
      // }
    },
    sideNavToggled: {
      transition: "all 0.5s ease-in-out",
      width: "65px",
      padding: "20px 10px 0 10px",
      overflow: "hidden",
      [theme.breakpoints.down(600)]:{
        width: '100% !important'
      }
    },
    brandLogoContainer: {
      display: "flex",   
      width: "120px",
      height: "44px",

      "& img": {
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.down(600)]:{
        display: 'none'
      }
    },
    navigationListContainer: {
      marginTop: "30px",
      height: "61.3%",
      [theme.breakpoints.down("sm")]:{
        display: "flex",
        marginTop: 0,
      height: "auto",
      width: '100%',
      justifyContent: 'space-between'
      }
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: "transparent",
      marginBottom: "10px",
      boxSizing: "border-box",
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down(600)]:{
        "& p": {
          display: 'none'
        },
        "& div": {
          display: 'none'
        }
      }
    },
    listItemActive: {
      backgroundColor: theme.palette.primary.light,
    },
    listIcon: {
      height: "20px",
      width: "20px",
      [theme.breakpoints.between(1560, 1700)]: {
        height: "22px",
        width: "22px",
      },
      [theme.breakpoints.between(1650, 1850)]: {
        height: "24px",
        width: "24px",
      },
      [theme.breakpoints.between(1851, 2000)]: {
        height: "24px",
        width: "24px",
      },
    },
    list: {
      width: "80%",
      textAlign: "left",
      marginLeft: "15px !important",
      color: "#4A5568",
      [theme.breakpoints.between(1560, 1700)]: {
        fontSize: "18px !important",
      },
      [theme.breakpoints.between(1650, 1850)]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.between(1851, 2000)]: {
        fontSize: "20px !important",
      },
    },
    listActive: {
      color: "#ffffff",
    },
    bottomImage: {
      position: "absolute",
      bottom: "-3px",
      left: "20px",
      width: "150px",
      transition: "opacity 0.5s ease-in-out",
      opacity: 1,
      "& img": {
        width: "100%",
      },
      [theme.breakpoints.down(600)]: {
        display: 'none'
      }
    },
    imageToggle: {
      transition: "opacity 0.5s ease-in-out",
      opacity: 0,
    },
  })
);
function SideNavigation() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { toggle } = useSelector(
    (state: ReducersModel) => state.toggleSideNavReducer
  );
  const { data } = useSelector((state: ReducersModel) => state.userDataReducer);
  const [pathName, setPathName] = React.useState("");
  React.useEffect(() => {
    setPathName(window.location.pathname.split("/")[1]);
  }, []);
  const changeRoute = (path: string) => {
    navigate(`/${path}`);
    setPathName(window.location.pathname.split("/")[1]);
  };
  return (
    <div
      className={
        toggle
          ? `${classes.sideNavigationContainer} ${classes.sideNavToggled}`
          : classes.sideNavigationContainer
      }
    >
      <div className={classes.brandLogoContainer}>
        <img src={LocalImages.BRANDLOGO} alt="brand_logo" />
        <figure
          style={
            toggle
              ? {
                  width: "155px",
                  height: "45px",
                  margin: "0px",
                  opacity: 0,
                  transition: "opacity 0.5s ease-in-out",
                }
              : {
                  width: "155px",
                  height: "45px",
                  margin: "0px",
                  opacity: 1,
                  transition: "opacity 0.5s ease-in-out",
                }
          }
        >
          <img src={LocalImages.BRANDNAME} alt="brand_name" />
        </figure>
      </div>
      <div className={classes.navigationListContainer}>
        {sideNavigation.map((value, index) => {
          if(value.name === "Teams") {
             if(data && data.accountType === "team") {
              return (
                <div
                  onClick={() => changeRoute(value.path)}
                  className={
                    value.path === pathName
                      ? `${classes.listItem} ${classes.listItemActive}`
                      : classes.listItem
                  }
                  key={index}
                >
                  <img
                    src={
                      value.path === pathName
                        ? value.activeIcon
                        : value.inactiveIcon
                    }
                    alt="sideNav_Icon"
                    className={classes.listIcon}
                  />
                  <Typography
                    className={
                      value.path === pathName
                        ? `${classes.list} ${classes.listActive}`
                        : classes.list
                    }
                    style={
                      toggle
                        ? { opacity: 0, transition: "opacity 0.5s ease-in-out" }
                        : { opacity: 1, transition: "opacity 0.5s ease-in-out" }
                    }
                    variant="body2"
                  >
                    {value.name}
                  </Typography>
                  {value.path === pathName ? (
                    <div
                      style={{
                        height: "20px",
                        width: "2px",
                        backgroundColor: "#fff",
                      }}
                    ></div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              );
             }
          } else {
            return (
              <div
                onClick={() => changeRoute(value.path)}
                className={
                  value.path === pathName
                    ? `${classes.listItem} ${classes.listItemActive}`
                    : classes.listItem
                }
                key={index}
              >
                <img
                  src={
                    value.path === pathName
                      ? value.activeIcon
                      : value.inactiveIcon
                  }
                  alt="sideNav_Icon"
                  className={classes.listIcon}
                />
                <Typography
                  className={
                    value.path === pathName
                      ? `${classes.list} ${classes.listActive}`
                      : classes.list
                  }
                  style={
                    toggle
                      ? { opacity: 0, transition: "opacity 0.5s ease-in-out" }
                      : { opacity: 1, transition: "opacity 0.5s ease-in-out" }
                  }
                  variant="body2"
                >
                  {value.name}
                </Typography>
                {value.path === pathName ? (
                  <div
                    style={{
                      height: "20px",
                      width: "2px",
                      backgroundColor: "#fff",
                    }}
                  ></div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            );
          }
          // return (
          //   <div
          //     onClick={() => changeRoute(value.path)}
          //     className={
          //       value.path === pathName
          //         ? `${classes.listItem} ${classes.listItemActive}`
          //         : classes.listItem
          //     }
          //     key={index}
          //   >
          //     <img
          //       src={
          //         value.path === pathName
          //           ? value.activeIcon
          //           : value.inactiveIcon
          //       }
          //       alt="sideNav_Icon"
          //       className={classes.listIcon}
          //     />
          //     <Typography
          //       className={
          //         value.path === pathName
          //           ? `${classes.list} ${classes.listActive}`
          //           : classes.list
          //       }
          //       style={
          //         toggle
          //           ? { opacity: 0, transition: "opacity 0.5s ease-in-out" }
          //           : { opacity: 1, transition: "opacity 0.5s ease-in-out" }
          //       }
          //       variant="body2"
          //     >
          //       {value.name}
          //     </Typography>
          //     {value.path === pathName ? (
          //       <div
          //         style={{
          //           height: "20px",
          //           width: "2px",
          //           backgroundColor: "#fff",
          //         }}
          //       ></div>
          //     ) : (
          //       <React.Fragment></React.Fragment>
          //     )}
          //   </div>
          // );
        })}
      </div>
      <div
        className={
          toggle
            ? `${classes.bottomImage} ${classes.imageToggle}`
            : classes.bottomImage
        }
      >
        <img src={LocalImages.GROUP} alt="group_image" />
      </div>
    </div>
  );
}

export default SideNavigation;
