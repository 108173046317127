class LoadingModel {
  isLoading: boolean = false;
}
class UserDataModel {
  data: any = {};
}
class SignUpDataModel {
  SFullName: string = "";
  SEmail: string = "";
  verificationEmail = "";
  loginEmail = "";
  loginPassword = "";
  accountType: string = "Individual";
  brokerage: string = "";
  licenseNumber: string = "";
  country: string = "";
  state: string = "";
  city: string = "";
  password: string = "";
  confirmPassword: string = "";
  autoGeneratedPassword: boolean = localStorage.getItem("isAutoGenerated")
    ? true
    : false;
  oldPassword: string = "";
  newPassword: string = "";
}
class ToggleSidenavModel {
  toggle: boolean = false;
}
class SocialSignInModel {
  profileObj: any = {};
  socialId: any = "";
  fullName: string = "";
  email: string = "";
  data: any = [];
  loginType: any = "";
  profilePicture: any = "";
}
class DashboardDataModal {
  dashboardData: any = {};
  toggleModal: boolean = false;
  favData: any = {};
}
class TeamMembersDataModal {
  teamMembersData: any = {};
  memberData: any = {};
  queryParameters: any = {
    sortBy: "",
    sortOrder: "",
    pageNo: 1,
    limit: 10,
    totalPages: 1,
  };
}
class CompanyDataModal {
  companyData: any = {};
}
class TemplateListDataModal {
  templateData: any = {};
  templateListModal: boolean = false;
  templateDataQueryParameters: any = {
    pageNo: 1,
    limit: 10,
    totalPages: 1,
    type: 'brochure'
  };
}
class TemplateDetailsModal {
  templateDetailsData: any = {};
  fileUrl: string = "";
  mediaType: string = "";
}
class CategoryListDataModal {
  categoryListData: any = {};
  categoryListDataQueryParameters: any = {
    pageNo: 1,
    limit: 10,
    totalPages: 1,
  };
}
class FavListModal {
  favList: any = {};
  favList1: any = [];
  favListDataQueryParameters: any = {
    pageNo: 1,
    limit: 10,
    totalPages: 1,
  };
}
class FavTemplateListModal {
  favTemplateList: any = {};
  type: any = "template";
  favTemplateListDataQueryParameters: any = {
    pageNo: 1,
    limit: 10,
    totalPages: 1,
  };
}
class EditableComponentModal {
  customizable_components: any = {};
  data: any = {};
}
class InputMatrixModal {
  inputMatrixes: any = {};
}
class videoUrlModal {
  videoUrl: any = {};
}
class FaqModal {
  data: any = [];
}

class EditImageModal {
  customizable_components: any = {};
  data: any = {};
  index: number = 1;
}

class ToggleModal {
  toggleModal: boolean = false;
  toggleModal1: boolean = false;
  schedulePostModal: boolean = false;
  facebookPagesModal: boolean = false;
  schedulePostFacebookModal: boolean = false;
  schedulePostLinkedinModal: boolean = false;
  deleteScheduleModal: boolean = false;
  editScheduleModal: boolean = false;
  instagramPageModal: boolean = false;
  schedulePostInstagramModal: boolean = false;
  type: number = 1;
  title: string = "";
  listData?: any = {};
}
class SearchResultModal {
  search: string = "";
  recentSearchResult: any = [];
  searchResult: any = {};
  category: any = [];
  type: any = "template";
  recentSearchResultDataQueryParameters: any = {
    pageNo: 1,
    limit: 10,
    totalPages: 1,
  };
}
class DropdownModal {
  profileDropdown: boolean = false;
  filterDropdown: boolean = false;
}
class SupportModel {
  data: any = {};
}
class EditProfileModel {
  data: any = {};
}
class ProfileModel {
  name: string = "";
  email: string = "";
  accountType: string = "";
  brokerage: string = "";
  licenseNumber: string = "";
  country: string = "";
  state: string = "";
  city: string = "";
  profilePicture: string = "";
  isLoading: boolean = false;
  isImageLoading: boolean = true;
}

class MyTemplateModel {
  myTemplate: any = {};
  type: any = "template";
  progressStatus: any = "draft";
  isLoading: boolean = false;
  selectedId: string = "";
  shareId: string = "";
  myTemplateDataQueryParameters: any = {
    pageNo: 1,
    limit: 10,
  };
}

class SubscriptionPlanModel {
  plans: any = {};
}
class FacebookLoginModel {
  data: any = {};
  accessToken: string = "";
  name: string = "";
}
class FaceBookPagesModel {
  data: any = [];
  pageId: string = "";
  accessToken: string = "";
  name: string = "";
}

class ScheduleFacebookPostModel {
  data: any = [];
  scheduled_publish_time: any = "";
  access_token: string = "";
  pageId: string = "";
  file_url: string = "";
  description: string = "";
  mediaType: string = "";
}
class LinkedinLoginModel {
  authToken: string = "";
  expiry: string = "";
}
class LinkedinProfileModel {
  name: string = "";
  data: any = {};
}
class SaveDataModel {
  access_token: string = "";
  expiry: string = "";
  name: string = "";
}
class ScheduleLinkedinPostModel {
  data: any = [];
  scheduled_publish_time: any = "";
  access_token: string = "";
  pageId: string = "";
  file_url: string = "";
  description: string = "";
  mediaType: string = "";
}
class InstaAccessModel {
  code: string = "";
  lastPath: string = "";
}

class PaymentHistoryModal {
  paymentHistoryData: any = {};
  paymentHistoryDataDataQueryParameters: any = {
    pageNo: 1,
    limit: 10,
    totalPages: 1,
  };
}
class SubscriptionValidDataModal {
  subscriptionValidData: any = {};
}
class EditScheduledPostModel {
  data: any = {};
}
class GetDownladableUrl {
  url: string = "";
}
class LinkedAccountModel {
  data: any = {};
  skeleton: boolean = false;
}

class ShareSocialMediaModel {
  openState: boolean = false
}

class CurrentSelectedPostModel {
  selectedPost : any = {}
}

class IsThumbnailShowModel {
  thumbnailState: boolean = false
}

class ReducersModel {
  globalLoaderReducer: LoadingModel = new LoadingModel();
  userDataReducer: UserDataModel = new UserDataModel();
  signUpDataReducer: SignUpDataModel = new SignUpDataModel();
  toggleSideNavReducer: ToggleSidenavModel = new ToggleSidenavModel();
  socialSignInReducer: SocialSignInModel = new SocialSignInModel();
  dashboardDataReducer: DashboardDataModal = new DashboardDataModal();
  templateListDataReducer: TemplateListDataModal = new TemplateListDataModal();
  teamMembersDataReducer: TeamMembersDataModal = new TeamMembersDataModal();
  companyDataReducer: CompanyDataModal = new CompanyDataModal();
  categoryListDataReducer: CategoryListDataModal = new CategoryListDataModal();
  templateDetailsReducer: TemplateDetailsModal = new TemplateDetailsModal();
  favListReducer: FavListModal = new FavListModal();
  favTemplateListReducer: FavTemplateListModal = new FavTemplateListModal();
  editableComponentReducer: EditableComponentModal =
    new EditableComponentModal();
  inputMatrixReducer: InputMatrixModal = new InputMatrixModal();
  videoUrlReducer: videoUrlModal = new videoUrlModal();
  faqReducer: FaqModal = new FaqModal();
  toggleModalReducer: ToggleModal = new ToggleModal();
  searchResultReducer: SearchResultModal = new SearchResultModal();
  dropDownReducer: DropdownModal = new DropdownModal();
  supportReducer: SupportModel = new SupportModel();
  editProfileReducer: EditProfileModel = new EditProfileModel();
  profileReducer: ProfileModel = new ProfileModel();
  myTemplateReducer: MyTemplateModel = new MyTemplateModel();
  subscriptionPlanReducer: SubscriptionPlanModel = new SubscriptionPlanModel();
  facebookPagesReducer: FaceBookPagesModel = new FaceBookPagesModel();
  ScheduleFacebookPostReducer: ScheduleFacebookPostModel =
    new ScheduleFacebookPostModel();
  linkedInReducer: LinkedinLoginModel = new LinkedinLoginModel();
  saveDataReducer: SaveDataModel = new SaveDataModel();
  ScheduleLinkedinPostReducer: ScheduleLinkedinPostModel =
    new ScheduleLinkedinPostModel();
  linkedinProfileReducer: LinkedinProfileModel = new LinkedinProfileModel();
  instaAccessReducer: InstaAccessModel = new InstaAccessModel();
  paymentHistoryDataReducer: PaymentHistoryModal = new PaymentHistoryModal();
  subscriptionValidDataReducer: SubscriptionValidDataModal =
    new SubscriptionValidDataModal();
  facebookLoginReducer: FacebookLoginModel = new FacebookLoginModel();
  editScheduledPostReducer: EditScheduledPostModel =
    new EditScheduledPostModel();
  getDownladableUrlReducer: GetDownladableUrl = new GetDownladableUrl();
  linkedAccountReducer: LinkedAccountModel = new LinkedAccountModel();
  EditImageReducer: EditImageModal = new EditImageModal();
  ShareSocialMediaReducer: ShareSocialMediaModel = new ShareSocialMediaModel();
  CurrentSelectedPostReducer: CurrentSelectedPostModel = new CurrentSelectedPostModel();
  IsThumbnailShowReducer: IsThumbnailShowModel = new IsThumbnailShowModel()
}
export {
  ReducersModel,
  UserDataModel,
  SignUpDataModel,
  ToggleSidenavModel,
  SocialSignInModel,
  DashboardDataModal,
  TemplateListDataModal,
  TeamMembersDataModal,
  CompanyDataModal,
  CategoryListDataModal,
  TemplateDetailsModal,
  FavListModal,
  FavTemplateListModal,
  EditableComponentModal,
  InputMatrixModal,
  videoUrlModal,
  ToggleModal,
  SearchResultModal,
  DropdownModal,
  FaqModal,
  SupportModel,
  EditProfileModel,
  ProfileModel,
  MyTemplateModel,
  SubscriptionPlanModel,
  FaceBookPagesModel,
  ScheduleFacebookPostModel,
  LinkedinLoginModel,
  SaveDataModel,
  ScheduleLinkedinPostModel,
  LinkedinProfileModel,
  InstaAccessModel,
  PaymentHistoryModal,
  SubscriptionValidDataModal,
  FacebookLoginModel,
  EditScheduledPostModel,
  LinkedAccountModel,
  GetDownladableUrl,
  EditImageModal,
  ShareSocialMediaModel,
  CurrentSelectedPostModel,
  IsThumbnailShowModel
};
